import React, { Component } from "react";

import aboutImg from "../../../assets/images/about/about-home.jpg";

class About extends Component {
  render() {
    let title = "About",
      description =
        "At Parknexa, we envision cities where parking is no longer a source of stress and confusion. We aim to create smart, integrated parking solutions that not only save time and effort but also contribute to more organized and sustainable urban living.";
    return (
      <React.Fragment>
        <div className="about-wrapper">
          <div className="container">
            <div className="row row--35 align-items-center">
              <div className="col-lg-5 col-md-12">
                <div className="thumbnail">
                  <img className="w-100" src={aboutImg} alt="About Images" />
                </div>
              </div>

              <div className="col-lg-7 col-md-12">
                <div className="about-inner inner">
                  <div className="section-title">
                    <h2 className="title">{title}</h2>
                    <p className="description">{description}</p>
                  </div>
                  <div className="row mt--30 mt_sm--10">
                    <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                      <div className="about-us-list">
                        <h3 className="title">Who we are</h3>
                        <p>
                        Welcome to Parknexa, your innovative partner in transforming parking experiences for urban environments. We are a dynamic startup driven by the mission to revolutionize how parking is managed and utilized, making it seamless, efficient, and hassle-free for everyone involved.
                        </p>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                      <div className="about-us-list">
                        <h3 className="title">Our technology</h3>
                        <p>
                        At the heart of Parknexa is a sophisticated platform that integrates with mobile and
                         IoT devices, real-time data analytics, and intuitive software 
                         interfaces. Our technology provides up-to-date 
                         information and ensures seamless 
                         communication between drivers, parking operators, and city officials.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default About;
