import React, { Component } from "react";
import drivers from "../../assets/images/icons/drivers.png";
import municipality from "../../assets/images/icons/municipality.png";
import partners from "../../assets/images/icons/partners.png";

const ServiceList = [
  {
    icon: drivers,
    title: "Drivers",
    description:
      "Daily parking activities support.",
  },
  {
    icon: municipality,
    title: "Municipalities",
    description:
      "Key parking insights and management.",
  },
  {
    icon: partners,
    title: "Partners",
    description:
      "Promote and manage your business.",
  },
];

class ServiceOne extends Component {
  render() {
    return (
      <React.Fragment>
        <div className="row">
          {ServiceList.map((val, i) => (
            <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
              <div className="service service__style--1">
                <div className="icon">
                  <img src={val.icon} alt="Digital Agency" />
                </div>
                <div className="content">
                  <h4 className="title">{val.title}</h4>
                  <p>{val.description}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </React.Fragment>
    );
  }
}
export default ServiceOne;
