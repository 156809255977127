import React, { Component , Fragment } from "react";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import SliderOne from "../component/slider/SliderOne";
import About from "../component/HomeLayout/homeOne/About";
import Helmet from "../component/common/Helmet";

class MainDemo extends Component{
    render(){
        return (
          <Fragment>
            <Helmet pageTitle="Home" />

            <Header
              headertransparent="header--transparent"
              colorblack="color--black"
              logoname="logo.png"
            />

            {/* Start Slider Area   */}
            <div className="slider-wrapper">
              <SliderOne />
            </div>
            {/* End Slider Area   */}

            {/* Start About Area */}
            <div className="about-area about-position-top pb--120">
              <About />
            </div>
            {/* End About Area */}

            {/* Start Back To Top */}
            <div className="backto-top">
              <ScrollToTop showUnder={160}>
                <FiChevronUp />
              </ScrollToTop>
            </div>
            {/* End Back To Top */}

            <Footer />
          </Fragment>
        );
    }
}
export default MainDemo;