import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import Breadcrumb from "../elements/common/Breadcrumb";
import CounterOne from "../elements/counters/CounterOne";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import about from "../assets/images/about/about-us-page.jpg";
import findingImg from "../assets/images/about/choose-parknexa-2.jpg";

class About extends Component {
  render() {
    let title = "About Us",
      description =
        "Welcome to Parknexa, your innovative partner in transforming parking experiences for urban environments. We are a dynamic startup driven by the mission to revolutionize how parking is managed and utilized, making it seamless, efficient, and hassle-free for everyone involved.";
    return (
      <React.Fragment>
        <PageHelmet pageTitle="About" />

        <Header
          headertransparent="header--transparent"
          colorblack="color--black"
          logoname="logo.png"
        />
        {/* Start Breadcrump Area */}
        <Breadcrumb title={"About Us"} />
        {/* End Breadcrump Area */}

        {/* Start About Area  */}
        <div className="rn-about-area ptb--120 bg_color--1">
          <div className="rn-about-wrapper">
            <div className="container">
              <div className="row row--35 align-items-center">
                <div className="col-lg-5">
                  <div className="thumbnail">
                    <img
                      className="w-100"
                      src={about}
                      alt="About Images"
                    />
                  </div>
                </div>
                <div className="col-lg-7">
                  <div className="about-inner inner">
                    <div className="section-title">
                      <h2 className="title">{title}</h2>
                      <p className="description">{description}</p>
                    </div>
                    <div className="row mt--30">
                      <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                        <div className="about-us-list">
                          <h3 className="title">Our Mission</h3>
                          <p>
                          Our mission is to provide cutting-edge parking solutions that cater to the diverse needs of drivers, municipalities, and businesses. We strive to leverage advanced technology to simplify parking, reduce congestion, and enhance the overall urban experience.
                          </p>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                        <div className="about-us-list">
                          <h3 className="title">Our Team</h3>
                          <p>
                          Our team comprises passionate professionals from diverse backgrounds, including software development, urban planning, and customer service. United by a common goal, we work tirelessly to innovate and improve our offerings, keeping user experience at the forefront of everything we do.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End About Area  */}

        {/* Start CounterUp Area */}
        <div className="rn-counterup-area pb--120 bg_color--1">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title text-center">
                  <h3 className="fontWeight500">Our Fun Facts</h3>
                </div>
              </div>
            </div>
            <CounterOne />
          </div>
        </div>
        {/* End CounterUp Area */}

        {/* Start Finding Us Area  */}
        <div className="rn-finding-us-area rn-finding-us bg_color--1">
          <div className="inner">
            <div className="content-wrapper">
              <div className="content">
                <h4 className="theme-gradient">Why Choose Parknexa?</h4>
                <ul>
          <li><strong>Innovation:</strong> We utilize the latest technologies to bring innovative solutions to traditional parking problems.</li>
          <li><strong>User-Centric:</strong> Our solutions are designed with the end-user in mind, ensuring ease of use and maximum convenience.</li>
          <li><strong>Sustainability:</strong> By optimizing parking, we help reduce traffic congestion and lower carbon emissions, contributing to greener cities.</li>
          <li><strong>Support:</strong> Our dedicated support team is always ready to assist, ensuring a smooth and satisfying experience for all our users.</li>
        </ul>
                <a className="rn-btn btn-white" href="/contact">
                  Get Started
                </a>
              </div>
            </div>
            <div className="thumbnail">
              <div className="image">
                <img src={findingImg} alt="Finding Images" />
              </div>
            </div>
          </div>
        </div>
        {/* End Finding Us Area  */}

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}

        <Footer />
      </React.Fragment>
    );
  }
}
export default About;
